/**
 * dependencies
 */
import React from "react"
import style from "./style.module.css"
import PropTypes from "prop-types"
import classnames from "classnames"

const Headline = ({ children, classes }) => (
  <h2 className={classnames([style.headline, classes])}>
    <span className={classnames([style.lines, style.first])}>Participe e </span>
    <strong className={classnames(style.lines, style.second)}>Mostre </strong>
    <span className={classnames([style.lines, style.third])}>
      <i className={style.dots}>.</i>
      <i className={style.dots}>.</i>
      <i className={style.dots}>.</i>
      <span className={style.third__text}>seu</span>
    </span>
    <strong className={classnames([style.lines, style.fourth])}>Talento</strong>
  </h2>
)

Headline.propTypes = {
  classes: PropTypes.string,
}

Headline.defaultProps = {
  classes: "",
}

export { Headline }

/**
 * dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import style from "./style.module.css"
import formStyle from "./../form-register/style.module.css"

class FieldCheckbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  render() {
    const { props } = this
    const {
      alert,
      classes,
      checked,
      dirty,
      inputHandler,
      label,
      required,
      name,
    } = props

    return (
      <div className={classnames([style.container, classes])}>
        <input
          className={style.field}
          id={name}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={inputHandler}
        />
        <label className={style.label} htmlFor={name}>
          {label}
        </label>
        {dirty && required && !checked && (
          <span className={classnames([style.alert, formStyle.animateFadeIn])}>
            {alert}
          </span>
        )}
      </div>
    )
  }
}

FieldCheckbox.propTypes = {
  alert: PropTypes.string,
  classes: PropTypes.string,
  dirty: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  inputHandler: PropTypes.func,
  checked: PropTypes.bool,
}

FieldCheckbox.defaultProps = {
  alert: "",
  dirty: "",
  required: false,
  checked: false,
  classes: "",
  inputHandler: () => {},
}

export { FieldCheckbox }

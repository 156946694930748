/**
 * dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import style from "./style.module.css"
import formStyle from "./../form-register/style.module.css"

class FieldText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValid: false,
    }
    this.fieldRef = React.createRef()
    this.changeHandler = this.changeHandler.bind(this)
  }

  changeHandler(e) {
    const { props } = this
    const { inputHandler, validator } = props

    this.setState({
      isValid: validator(e.target.value, this.fieldRef),
    })
    inputHandler(e)
  }

  render() {
    const { changeHandler, props, state } = this
    const {
      alert,
      classes,
      dirty,
      label,
      onBlurHandler,
      name,
      type,
      required,
      value,
    } = props
    const showAlert = required && !state.isValid && dirty
    const classesList = [style.container, classes]
    if (value !== "") {
      classesList.push(style.withValue)
    }

    return (
      <div className={classnames(classesList)}>
        <input
          className={style.field}
          id={name}
          name={name}
          type={type}
          onChange={changeHandler}
          value={value}
          autoComplete="off"
          onBlur={onBlurHandler}
          ref={this.fieldRef}
        />
        <label className={style.label} htmlFor={name}>
          {label}
        </label>
        {showAlert && (
          <span className={classnames([style.alert, formStyle.animateFadeIn])}>
            {alert}
          </span>
        )}
      </div>
    )
  }
}

FieldText.propTypes = {
  alert: PropTypes.string,
  classes: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  dirty: PropTypes.bool,
  type: PropTypes.string,
  onBlurHandler: PropTypes.func,
  inputHandler: PropTypes.func,
  validator: PropTypes.func,
  value: PropTypes.string,
}

FieldText.defaultProps = {
  alert: "Por favor, verifique o valor do campo acima",
  classes: "",
  dirty: false,
  required: false,
  type: "text",
  value: "",
  validator: () => true,
  onBlurHandler: () => {},
  inputHandler: () => {},
}

export { FieldText }

/**
 * dependencies
 */
import React from "react"
import style from "./style.module.css"
import classnames from "classnames"
import { FieldText } from "../field-text"
import { FieldCheckbox } from "../field-checkbox"

/**
 * constants/settings
 */
const maskPhone = {
  maskPatern: "(00)0?0000-0000",
  maskCharacter: "0",
}
const phoneExp = new RegExp(/^\(\d{2}\)\d{4,5}-\d{4}$/)

class FormRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      agreement: false,
      newsletter: false,

      sent: false,
      dirty: false,
      invalidRecaptcha: true,
    }
    this.applyMask = this.applyMask.bind(this)
    this.inputHandler = this.inputHandler.bind(this)
    this.submitHandler = this.submitHandler.bind(this)

    this.onBlurPhone = this.onBlurPhone.bind(this)
    this.validateName = this.validateName.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
    this.validatePhone = this.validatePhone.bind(this)
    this.validateAgreement = this.validateAgreement.bind(this)
    this.validateNewsletter = this.validateNewsletter.bind(this)
    this.changedResponse = this.changedResponse.bind(this)
    this.validateRecaptcha = this.validateRecaptcha.bind(this)
    this.recapchaRef = React.createRef()
  }

  componentDidMount() {
    const script = document.createElement("script")
    const onloadHandler = () => {
      if (!global.grecaptcha) {
        setTimeout(onloadHandler, 100)
      } else {
        global.grecaptcha.ready(() => {
          global.grecaptcha.render(this.recapchaRef.current, {
            theme: "dark",
            sitekey: process.env.RECAPTCHA_CLIENT,
            callback: key => {
              this.setState({
                invalidRecaptcha: !key,
              })
            },
          })
        })
      }
    }
    script.src = "https://www.google.com/recaptcha/api.js?hl=pt-BR"
    script.onload = () => setTimeout(onloadHandler)
    document.head.appendChild(script)
  }

  applyMask(value, settings) {
    const { maskPatern, maskCharacter } = settings

    if (maskPatern === "" || value === "") {
      return value
    } else {
      const allowedChars = [maskCharacter]
      const valuePeaces = ("" + value.replace(/\D/g, "")).split("")
      const filteredMaskCharacters = maskPatern
        .split("")
        .filter(char => allowedChars.concat("?").indexOf(char) >= 0)
      const mask =
        valuePeaces.length + 1 >= filteredMaskCharacters.length
          ? maskPatern.replace(/.\?/, maskCharacter)
          : maskPatern.replace(/.\?/, "")
      const output = mask
        .split("")
        .reduce((prev, peace) => {
          if (peace === maskCharacter) {
            const [valuePeace] = valuePeaces.splice(0, 1)
            prev.push(valuePeace)
          } else {
            prev.push(peace)
          }
          return prev
        }, [])
        .join("")
      return output
    }
  }

  changedResponse(token) {
    console.log(token)
  }

  validateName(value) {
    return ("" + value).length > 0
  }

  validateEmail(value, field) {
    return ("" + value).length > 0 && field.current.checkValidity()
  }

  validatePhone(value) {
    return value.length < 1 || phoneExp.test(value)
  }

  validateAgreement(value) {
    return true
  }

  validateNewsletter(value) {
    return true
  }

  onBlurPhone(e) {
    if (!this.validatePhone(e.target.value)) {
      e.target.value = ""
      this.setState({
        phone: "",
      })
    }
  }

  submitHandler(e) {
    const { state, validateRecaptcha } = this
    const { name, email, phone, agreement, newsletter } = state
    e.preventDefault()
    const recaptchaKey = validateRecaptcha()
    this.setState({
      dirty: true,
    })

    if (!agreement) {
      return false
    }
    if (!recaptchaKey) {
      this.setState({
        invalidRecaptcha: true,
      })
      return false
    }
    this.setState({
      invalidRecaptcha: true,
    })
    this.sendData({
      name,
      email,
      phone,
      newsletter,
      recaptchaKey,
    })
  }

  inputHandler(e) {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      })
    } else if (e.target.name === "phone") {
      this.setState({
        [e.target.name]: this.applyMask(e.target.value, maskPhone),
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  sendData(payload) {
    const xhr = new XMLHttpRequest()
    xhr.open("POST", process.env.ENDPOINT)
    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          this.setState({
            sent: true,
          })
        } else {
          alert("Erro ao enviar os dados para a API")
        }
      }
    })
    xhr.send()
  }

  validateRecaptcha() {
    if (!global.grecaptcha || !global.grecaptcha.getResponse) {
      return ""
    }
    return true
  }

  render() {
    const { inputHandler, onBlurPhone, submitHandler, state } = this
    const { dirty, invalidRecaptcha } = state
    return (
      <div className={style.container} id="register">
        {state.sent && (
          <div className={style.sentContent}>
            <div
              className={classnames([style.sentContainer, style.animateFadeIn])}
            >
              <h4 className={style.sentTitle}>
                Cadastro realizado com sucesso !
              </h4>
              <p className={style.sentDescription}>
                Aguarde o nosso contato, em breve receberá uma notícia no seu
                e-mail
              </p>
            </div>
          </div>
        )}
        {!state.sent && (
          <div className={style.content}>
            <header className={style.header}>
              <h3 className={style.title}>Quero me cadastrar.</h3>
              <p className={style.description}>
                Faça seu cadastro para saber tudo <br />
                sobre o <strong>Concurso Musical Mostre seu Talento.</strong>
              </p>
              <small className={style.info}>
                Todos os campos marcados com (*) são obrigatórios.
              </small>
            </header>
            <form
              className={style.form}
              onSubmit={submitHandler}
              autoComplete="off"
            >
              <FieldText
                classes={style.fields}
                inputHandler={inputHandler}
                value={state.name}
                dirty={dirty}
                validator={this.validateName}
                name="name"
                label="Nome*"
                required
              />
              <FieldText
                classes={style.fields}
                inputHandler={inputHandler}
                value={state.email}
                dirty={dirty}
                validator={this.validateEmail}
                name="email"
                label="E-mail*"
                type="email"
                required
              />
              <FieldText
                classes={style.fields}
                inputHandler={inputHandler}
                onBlurHandler={onBlurPhone}
                value={state.phone}
                dirty={dirty}
                name="phone"
                label="Telefone"
              />
              <div className={style.checkboxes}>
                <FieldCheckbox
                  classes={style.fields}
                  inputHandler={inputHandler}
                  checked={state.agreement}
                  name="agreement"
                  dirty={dirty}
                  required
                  validator={this.validateAgreement}
                  label="Eu aceito as Políticas de Privacidade do Concurso Musical *"
                  alert="É necessário que concorde com as políticas de privacidade"
                />
                <FieldCheckbox
                  classes={style.fields}
                  inputHandler={inputHandler}
                  checked={state.newsletter}
                  name="newsletter"
                  dirty={dirty}
                  validator={this.validateNewsletter()}
                  label="Gostaria de receber outras novidades do Concurso Musical *"
                />
              </div>
              <div className={style.actions}>
                <div ref={this.recapchaRef}></div>
                {dirty && invalidRecaptcha && (
                  <span
                    className={classnames([
                      style.warningRecaptcha,
                      style.animateFadeIn,
                    ])}
                  >
                    Falha ao confirmar que não é um robo, por favor, verifique
                    novamente.
                  </span>
                )}
              </div>
              <div className={style.actions}>
                <button className={style.submit}>
                  <span className={style.send}>Enviar</span>
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    )
  }
}

FormRegister.propTypes = {}

FormRegister.defaultProps = {}

export { FormRegister }

/**
 * dependencies
 */
import React from "react"
import classnames from "classnames"
import style from "./style.module.css"
import prize1 from "./../../images/prize-01.png"
import prize2 from "./../../images/prize-02.png"
import { CustomButton } from "../custom-button"

const HomeCampaign = () => (
  <div className={style.campaign}>
    <div className={style.prizes}>
      <img
        className={classnames([style.prize, style.tv])}
        src={prize1}
        alt="Premio de Smart TV Ultra HD"
      />
      <img
        className={classnames([style.prize, style.phone])}
        src={prize2}
        alt="Premio de Smartphone"
      />
    </div>
    <div className={style.description}>
      <p className={style.regulation}>
        Seu talento pode ser premiado
        <br />
        com um <strong>Premio Especial</strong> e uma
        <br />
        <strong>Viagem Virtual.</strong>
      </p>
      <CustomButton classes={style.register}>
        <a className={style.link} href="#register">Cadastre-se</a>
      </CustomButton>
    </div>
  </div>
)

HomeCampaign.propTypes = {}

export { HomeCampaign }

/**
 * dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import style from "./style.module.css"
import classNames from "classnames"

const CustomButton = ({ children, classes }) => (
  <button className={classNames([style.button, classes])}>{children}</button>
)

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
}

CustomButton.defaultProps = {
  classes: "",
}

export { CustomButton }

/**
 * dependencies
 */
import logo from "../../images/logo.png"
import React from "react"
import style from "./style.module.css"
import { Container } from "../container"
import { Headline } from "../headline"
import { ArtistQuote } from "../artist-quote"
import { HomeCampaign } from "../home-campaign"
import { FormRegister } from "../form-register"
import { Footer } from "../footer"

const artistQuote = `“Nada é tão grande que<br/>seu sonho não alcance”`

const Home = ({ children }) => (
  <div className={style.home}>
    <div className={style.heading} id="introduction">
      <Container classes={style.container}>
        <div className={style.logo}>
          <img src={logo} className={style.logoImage} alt="Concurso musical" />
        </div>
        <Headline classes={style.headline} />
        <div className={style.quote}>
          <ArtistQuote author={"Amanda Mittz"} quote={artistQuote} />
        </div>
        <HomeCampaign />
      </Container>
    </div>
    <FormRegister />
    <Footer />
  </div>
)

Home.propTypes = {}

export { Home }

/**
 * dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import style from "./style.module.css"
import classnames from "classnames"

const ArtistQuote = ({ author, quote, classes }) => (
  <p className={classnames([style.quote, classes])}>
    <span className={classnames([style.quote__author])}>{author}</span>
    <q
      className={classnames([style.quote__content])}
      dangerouslySetInnerHTML={{ __html: quote }}
    ></q>
  </p>
)

ArtistQuote.propTypes = {
  author: PropTypes.string,
  quote: PropTypes.string,
  classes: PropTypes.string,
}

ArtistQuote.defaultProps = {
  author: "",
  quote: "",
}

export { ArtistQuote }
